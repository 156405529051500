import React from "react"

import { main, logo, title, contact } from "./main.module.css"
import logoImg from "../../images/logo.svg"

export default function Main() {
  return (
    <div>
      <div className={main}>
        <img className={logo} src={logoImg} alt="De Jongens" />
        <h1 className={title}>Ain’t got no time for this.</h1>
        <div className={contact}>
          <h3>please reach out</h3>
          <strong>Yuri Vandenbogaerde</strong>{" "}
          <span className="no-wrap">
            / <a href="mailto:yuri@dejongens.eu">yuri@dejongens.eu</a> /{" "}
            <a href="tel:0032478272360">+32 478 27 23 60</a>
          </span>
          <br />
          <strong>Dries Henau</strong>
          <span className="no-wrap">
            / <a href="mailto:dries@dejongens.eu">dries@dejongens.eu</a> /{" "}
            <a href="tel:0032487317085">+32 487 31 70 85</a>
          </span>{" "}
          <br />
          <a href="mailto:info@dejongens.eu">info@dejongens.eu</a> —
          Henegouwenstraat 95/301, 9000 Gent
        </div>
      </div>
    </div>
  )
}
